import classNames from 'classnames';
import { useId } from 'hooks';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  const id = useId();
  const clipId = `monogram-clip-${id}`;

  return (
    <svg
      aria-hidden
      className={classNames('monogram', className)}
      width="46"
      height="29"
      viewBox="0 0 46 29"
      {...props}
    >
      <defs>
        <clipPath id={clipId}>
          <path d="M 23.4 1.175 c -8.0777 0 -14.625 6.5473 -14.625 14.625 S 15.3223 30.425 23.4 30.425 s 14.625 -6.5473 14.625 -14.625 S 31.4777 1.175 23.4 1.175 M 23.4 29.0943 V 2.5057 c 7.3429 0 13.2943 5.9514 13.2943 13.2943 S 30.7429 29.0943 23.4 29.0943" />
        </clipPath>
      </defs>
      <rect clipPath={`url(#${clipId})`} width="100%" height="100%" />
      {highlight && (
        <g clipPath={`url(#${clipId})`}>
          <rect className="monogram__highlight" width="100%" height="100%" />
        </g>
      )}
    </svg>
  );
}

export default Monogram;
